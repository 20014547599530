/* src/@partials/layout/topbar/select-office/select-office.component.scss */
button {
  position: relative;
  z-index: 10;
  background: none;
  border: none;
  color: #ffffff;
}
.hidden-select {
  position: absolute;
  max-width: 200px;
  height: 0;
  overflow: hidden;
  opacity: 0;
}
::ng-deep .mat-mdc-option .mdc-list-item__primary-text {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: 100% !important;
}
/*# sourceMappingURL=select-office.component.css.map */
