import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { KeyStorage } from '@utils/classes/KeyStorage';
import { ListaCotizacionComponent } from 'app/modules/gestion/cotizacion/lista-cotizacion/lista-cotizacion.component';
import { NotificationService } from 'app/services/notification.service';
import { environment } from 'environments/environment';
import { SacCotizacion } from '~models/acuerdos-comerciales/SacCotizacion';
import { DateUtil } from '~shared/utils/DateUtil';

interface IStorageNotifications {
	notifications: SacCotizacion[];
}
@Component({
	selector: 'app-notification',
	templateUrl: './notification.component.html',
	styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
	@ViewChild(ListaCotizacionComponent) listaCotizacionComponent!: ListaCotizacionComponent;
	// Show dot on top of the icon
	@Input() dot!: string;

	// Show pulse on icon
	@Input() pulse!: boolean;

	@Input() pulseLight!: boolean;

	// Set icon class name
	@Input() icon = 'flaticon2-bell-alarm-symbol';
	@Input() iconType!: '' | 'success';

	// Set true to icon as SVG or false as icon class
	@Input() useSVG!: boolean;

	// Set bg image path
	@Input() bgImage!: string;

	// Set skin color, default to light
	@Input() skin: 'light' | 'dark' = 'light';

	@Input() type: 'brand' | 'success' = 'success';

	// Propiedades para las notificaciones
	keyStorage = new KeyStorage();
	objNotifications: IStorageNotifications = { notifications: [] };
	notifications: SacCotizacion[] = [];
	notificationCounter: number = 0;
	disable: boolean = false;

	// Propiedades para el título de página
	currentTitle!: string;

	constructor(private cdr: ChangeDetectorRef, private notificationService: NotificationService) {}

	ngOnInit(): void {
		if (this.keyStorage.getStorage(environment.notifications) !== null) {
			this.notifications = this.keyStorage.getStorage(environment.notifications).notifications;
			this.objNotifications.notifications = [...this.notifications];
			this.notificationCounter = this.notifications.filter((not) => not.readStatus === false).length;
			this.cdr.detectChanges();
		}
		this.notificationService.newNotification$.subscribe((res) => {
			let newNoti: SacCotizacion = { ...res, readStatus: false, dateTime: new Date() };
			this.notifications.push(newNoti);
			this.objNotifications.notifications.push(newNoti);
			this.keyStorage.setStorage(environment.notifications, this.objNotifications);
			this.notificationCounter++;
			this.cdr.detectChanges();
			toastr.warning(
				`<span style="font-size: 10px;"># ${newNoti.nroCotizacion} - ${newNoti.tbTipoManifiesto?.nombre} <br> ${newNoti.razonSocialCliente} le acaba de hacer una solicitud de cotización`,
				'Solicitud de Cotización !',
				{ iconClass: 'customer-info' }
			);
		});
	}

	isDisable() {
		if (this.notifications.length == 0) {
			return (this.disable = true);
		} else {
			return (this.disable = false);
		}
	}

	openNotification(e: SacCotizacion) {
		let index = this.notifications.findIndex((notification) => notification.razonSocialCliente === e.razonSocialCliente);
		this.notifications[index].readStatus = true;

		this.notifications.splice(0, index + 1);
		this.objNotifications.notifications = [...this.notifications];
		this.keyStorage.setStorage(environment.notifications, this.objNotifications);
		this.notificationCounter--;
		this.cdr.detectChanges();
	}

	marcarNotificationsComoLeido() {
		const lon = this.notifications.length;
		this.notifications.splice(0, lon);
		this.objNotifications.notifications = [...this.notifications];
		this.keyStorage.setStorage(environment.notifications, this.objNotifications);
		this.notificationCounter = 0;
		this.cdr.detectChanges();
	}

	fnDateTimeRegister(e: SacCotizacion) {
		return `Hace ${DateUtil.formatDistance(e.dateTime as Date)}`;
	}

	backGroundStyle(): string {
		if (!this.bgImage) {
			return 'none';
		}
		return 'url(' + this.bgImage + ')';
	}
}
