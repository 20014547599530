<div ngbDropdown placement="bottom" autoClose="outside" class="kt-header__topbar-item">
	<div ngbDropdownToggle class="kt-header__topbar-wrapper">
		<span
			class="kt-header__topbar-icon"
			[ngClass]="{
				'kt-pulse kt-pulse--brand': pulse,
				'kt-pulse--light': pulseLight,
				'kt-header__topbar-icon--success': iconType === 'success'
			}">
			<i *ngIf="!useSVG" class="containerIcon" [ngClass]="icon" style="color: #d3d3d3"></i>
			<span class="count" *ngIf="notificationCounter > 0">{{ notificationCounter }}</span>
			<span *ngIf="useSVG" class="kt-svg-icon" [ngClass]="{ 'kt-svg-icon--success': iconType === 'success' }" [inlineSVG]="icon"></span>
			<span class="kt-pulse__ring" [hidden]="!pulse"></span>
		</span>
		<span class="kt-badge kt-badge--dot kt-badge--notify kt-badge--sm kt-badge--brand" [hidden]="!dot"></span>
	</div>

	<div ngbDropdownMenu class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg">
		<!--begin: Head -->
		<div class="kt-head kt-head--skin-{{ skin }} kt-head--fit-x kt-head--fit-b" [ngStyle]="{ 'background-image': backGroundStyle() }">
			<h3 class="kt-head__title">
				Notificaciones&nbsp;
				<span class="btn btn-{{ type }} btn-sm btn-bold btn-font-md">{{ notificationCounter ?? 0 }} nuevos</span>
			</h3>
			<ul ngbNav #nav="ngbNav" ktTabClickEvent class="nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-{{ type }} kt-notification-item-padding-x" role="tablist">
				<!-- ALERTAS */ -->
				<li class="nav-item" [ngbNavItem]="1" id="tab-id-1">
					<a ngbNavLink class="nav-link active show" data-toggle="tab" href="javascript:;" role="tab" aria-selected="true">Alertas</a>
					<ng-template ngbNavContent>
						<div class="container">
							<div class="row">
								<div class="col-8">
									<button [disabled]="isDisable()" type="button" class="btn btn-link" (click)="marcarNotificationsComoLeido()">Marcar todos como leído</button>
								</div>
								<div class="col">
									<a [routerLink]="['/acuerdos-comerciales/cotizacion']" target="_blank">
										<button type="button" class="btn btn-outline-dark" [disabled]="isDisable()">Ver todo</button>
									</a>
								</div>
							</div>
							<hr />
						</div>

						<div
							[perfectScrollbar]="{ wheelPropagation: false }"
							[ngStyle]="{
								'max-height': '40vh',
								position: 'relative',
								'background-color': '#EEEEEE'
							}"
							class="kt-notification kt-margin-t-10 kt-margin-b-10"
							*ngIf="notifications.length > 0; else noNotification">
							<a
								class="kt-notification__item"
								*ngFor="let notification of notifications"
								[routerLink]="['/acuerdos-comerciales/cotizacion/', notification.nroCotizacion]"
								target="_blank"
								(click)="openNotification(notification)">
								<div class="kt-notification__item-icon">
									<img src="./assets/media/icons/svg/Documents/briefcase_black.svg" class="icon_cotizacion" />
								</div>
								<div class="kt-notification__item-details">
									<div>
										<h5>Solicitud de Cotización !</h5>
										<h6 style="font-size: 10px">
											#{{ notification.nroCotizacion }} -
											{{ notification.tbTipoManifiesto?.nombre }}
										</h6>
									</div>
									<div
										class="kt-notification__item-title"
										[ngClass]="{
											'fw-bold': notification.readStatus === false
										}">
										{{ notification.razonSocialCliente }}
										<p>le acaba de hacer una solicitud de cotización</p>
									</div>
									<div class="kt-notification__item-time">
										{{ fnDateTimeRegister(notification) }}
									</div>
								</div>
							</a>

							<div class="ps__rail-x" style="left: 0px; bottom: 0px">
								<div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px"></div>
							</div>
							<div class="ps__rail-y" style="top: 0px; right: 0px">
								<div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 0px"></div>
							</div>
						</div>
						<ng-template #noNotification>
							<div class="kt-grid kt-grid--ver" style="min-height: 200px">
								<div class="kt-grid kt-grid--hor kt-grid__item kt-grid__item--fluid kt-grid__item--middle">
									<div class="kt-grid__item kt-grid__item--middle kt-align-center"><br />Sin notificaciones.</div>
								</div>
							</div>
						</ng-template>
					</ng-template>
				</li>

				<!-- EVENTOS */ -->
				<li class="nav-item" [ngbNavItem]="2" id="tab-id-2">
					<a ngbNavLink class="nav-link" data-toggle="tab" href="javascript:;" role="tab" aria-selected="false">Eventos</a>
					<ng-template ngbNavContent>
						<div class="kt-grid kt-grid--ver" style="min-height: 200px">
							<div class="kt-grid kt-grid--hor kt-grid__item kt-grid__item--fluid kt-grid__item--middle">
								<div class="kt-grid__item kt-grid__item--middle kt-align-center"><br />Sin notificaciones.</div>
							</div>
						</div>
					</ng-template>
				</li>

				<!-- LOGS */ -->
				<li class="nav-item" [ngbNavItem]="3" id="tab-id-3">
					<a ngbNavLink class="nav-link" data-toggle="tab" href="javascript:;" role="tab" aria-selected="false">Logs</a>
					<ng-template ngbNavContent>
						<div class="kt-grid kt-grid--ver" style="min-height: 200px">
							<div class="kt-grid kt-grid--hor kt-grid__item kt-grid__item--fluid kt-grid__item--middle">
								<div class="kt-grid__item kt-grid__item--middle kt-align-center"><br />Sin notificaciones.</div>
							</div>
						</div>
					</ng-template>
				</li>
			</ul>
		</div>

		<!--end: Head -->

		<form>
			<div [ngbNavOutlet]="nav" class="mt-2"></div>
		</form>
	</div>
</div>
