<table class="table p-0" aria-label="Tabla Carga">
	<thead *ngIf="dataSacCotizacionConcepto && dataSacCotizacionConcepto.length > 0">
		<tr>
			<th scope="col" class="" style="width: 35%">
				<strong> {{ dataGrupos.nombre }} </strong>
			</th>
			<th scope="col" class="" style="width: 10%">Moneda</th>
			<th scope="col" class="" style="width: 15%">Unidad</th>
			<th scope="col" class="" style="width: 15%">Costo</th>
			<th scope="col" class="" style="width: 15%">Venta</th>
			<th scope="col" class="" style="width: 15%">Utilidad</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let concepto of dataSacCotizacionConcepto">
			<td style="width: 35%">
				<p>{{ concepto.descripcion }}</p>
			</td>
			<td style="width: 10%">
				<p class="p-0">{{ concepto.tbMoneda?.codigo }}</p>
			</td>
			<td style="width: 15%">
				<p class="p-0">{{ concepto.tbUnidadMedida?.nombre }}</p>
			</td>
			<td style="width: 15%">
				<p>{{ convertToDecimal(concepto.importeCompra, 3) }}</p>
			</td>
			<td style="width: 15%">
				<p>{{ convertToDecimal(concepto.importeVenta, 3) }}</p>
			</td>
			<td style="width: 15%">
				<p>{{ convertToDecimal(concepto.importeUtilidad, 3) }}</p>
			</td>
		</tr>
	</tbody>
</table>
