import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HTTP_INTERCEPTORS, HttpHeaders } from '@angular/common/http';

import { first, mergeMap, Observable } from 'rxjs';

import { CookiesService } from '~shared/services/coookies.service';
import { Store } from '@ngrx/store';
import { AppState } from '@core/store';
import { oficinaSelected } from '@core/store/selectors/oficinas.selectors';

@Injectable({
	providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
	constructor(private readonly _cookiesService: CookiesService, private store: Store<AppState>) {}

	// intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
	// 	return next.handle(getCloneRequest(this._cookiesService, req));
	// }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let clonedRequest: any;
		if (req.url.indexOf('sumax-erp-backend-maestros-sync') !== -1) {
			clonedRequest = this._cookiesService.getBearerAuthorization('maestroSyncToken');
			let headers = new HttpHeaders({ Authorization: clonedRequest });
			return next.handle(req.clone({ headers }));
		} else {
			clonedRequest = this._cookiesService.getBearerAuthorization('idToken');
			return this.store.select(oficinaSelected).pipe(
				first(),
				mergeMap((res) => {
					const headerObject: { [key: string]: any } = { Authorization: clonedRequest };
					if (res?.idOficina) {
						headerObject['idOficina'] = res.idOficina.toString();
					}
					let headers = new HttpHeaders(headerObject);
					return next.handle(req.clone({ headers }));
				})
			);
		}
	}
}

export const authInterceptorProviders = {
	provide: HTTP_INTERCEPTORS,
	useClass: AuthInterceptorService,
	multi: true,
};
