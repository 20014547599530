/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { BrowserModule, Title } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ThemeModule } from '@theme/theme.module';
import { AppRoutingModule } from './app.routing';
import { HttpClientModule } from '@angular/common/http';
import { LocationStrategy, HashLocationStrategy, registerLocaleData } from '@angular/common';
import { PartialsModule } from '@partials/partials.module';
import { TranslateModule } from '@ngx-translate/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule, RouterState } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

/*Cookies*/
import { CookieService } from 'ngx-cookie-service';
import { authInterceptorProviders } from '@core/security/interceptors/auth-interceptor.service';
import { errorHandlerInterceptorProviders } from '@core/security/interceptors/error-handler-interceptor.service';
import { CustomSerializer } from '@core/store/serializer/custom-serializer';
import { EffectsArray } from '@core/store/effects';
import { metaReducers, reducers } from '@core/store';

import localeEs from '@angular/common/locales/es';
import { IconAgGridRenderComponent } from './shared/components/icon-ag-grid-render/icon-ag-grid-render.component';
import { MatIconModule } from '@angular/material/icon';
import { CellEstadoRenderComponent } from '~shared/components/cell-estado-render/cell-estado-render.component';
import { HistorialEstadoModule } from '~shared/components/historial-estado/historial-estado.module.component';
import { IMqttServiceOptions, MqttModule } from 'ngx-mqtt';
import { environment } from 'environments/environment';
import { NgSelectSimpleEditorComponent } from './shared/components/cell-editors/ng-select-simple-editor/ng-select-simple-editor.component';
import { InputTextEditorComponent } from '~shared/components/cell-editors/input-text-editor/input-text-editor.component';
import { InputNumberModule, InputTextModule, NgSelectSimpleModule } from 'ngx-sumax-erp-component';
import { DEFAULT_CONFIG_INPUT_NUMBER, DEFAULT_CONFIG_INPUT_TEXT, DEFAULT_CONFIG_NGSELECT_SIMPLE } from '~shared/interface/ConfigSumaxComponent.intercaes';
import { InputNumberEditorComponent } from './shared/components/cell-editors/input-number-editor/input-number-editor.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VerCotiResumenComponent } from './modules/gestion/common/ver-coti-resumen/ver-coti-resumen.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ResumenListaConceptoComponent } from './modules/gestion/common/ver-coti-resumen/resumen-lista-concepto/resumen-lista-concepto.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SwUpdateNotifierService } from '@utils/services/sw-update-notifier.service';
import { NgxSpinnerModule } from 'ngx-spinner';

const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
	hostname: 'b-eb7e7655-db2c-471e-b330-9723b6344494-1.mq.us-east-1.amazonaws.com',
	port: 61619,
	username: 'admin',
	password: environment.MQTT_PASSWORD,
	protocol: 'wss',
	path: '/mqtt',
	keepalive: 720,
};

registerLocaleData(localeEs, 'es');

@NgModule({
	declarations: [
		AppComponent,
		CellEstadoRenderComponent,
		IconAgGridRenderComponent,
		InputTextEditorComponent,
		NgSelectSimpleEditorComponent,
		InputNumberEditorComponent,
		VerCotiResumenComponent,
		ResumenListaConceptoComponent,
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		ThemeModule,
		HttpClientModule,
		AppRoutingModule,
		HistorialEstadoModule,
		PartialsModule,
		// METRONIC
		TranslateModule.forRoot(),

		// NGRX
		StoreModule.forRoot(reducers, { metaReducers }),
		EffectsModule.forRoot(EffectsArray),
		StoreRouterConnectingModule.forRoot({
			stateKey: 'router',
			routerState: RouterState.Minimal,
			serializer: CustomSerializer,
		}),

		InputTextModule.forRoot(DEFAULT_CONFIG_INPUT_TEXT),
		InputNumberModule.forRoot(DEFAULT_CONFIG_INPUT_NUMBER),
		NgSelectSimpleModule.forRoot(DEFAULT_CONFIG_NGSELECT_SIMPLE),

		FormsModule,
		ReactiveFormsModule,

		//MAT
		MatIconModule,
		MatProgressSpinnerModule,
		StoreDevtoolsModule.instrument({ connectInZone: true }),

		MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.production,
			// Register the ServiceWorker as soon as the app is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:30000',
		}),
		NgxSpinnerModule.forRoot({ type: 'ball-square-clockwise-spin' }),
	],
	providers: [
		{
			provide: LocationStrategy,
			useClass: HashLocationStrategy,
		},
		{
			provide: LOCALE_ID,
			useValue: 'es',
		},
		Title,
		CookieService,
		authInterceptorProviders,
		errorHandlerInterceptorProviders,
		SwUpdateNotifierService,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
