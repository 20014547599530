import { Component, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { SacCotizacionConcepto } from '~models/acuerdos-comerciales';
import { SacCotizacion } from '~models/acuerdos-comerciales/SacCotizacion';
import { TbGrupoConcepto } from '~models/maestros-sync/maestros/TbGrupoConcepto';
import { DialogData } from '~shared/interface/DialogData';

@Component({
	selector: 'app-resumen-lista-concepto',
	templateUrl: './resumen-lista-concepto.component.html',
	styleUrls: ['./resumen-lista-concepto.component.scss'],
})
export class ResumenListaConceptoComponent implements OnChanges {
	@Input() dataGrupos!: TbGrupoConcepto;
	@Input() dataSacCotizacionConcepto!: SacCotizacionConcepto[] | [];

	dataGrupoConceptos$: Observable<TbGrupoConcepto[]> = of([]);
	dataConceptos$: Observable<SacCotizacionConcepto[]> = of();

	constructor(@Inject(MAT_DIALOG_DATA) public dialogData: DialogData<SacCotizacion>) {}

	ngOnChanges(changes: SimpleChanges): void {
		const cotiConcepto = changes.dataSacCotizacionConcepto;
		if (cotiConcepto && cotiConcepto.currentValue != null) {
			this.dataSacCotizacionConcepto = (cotiConcepto.currentValue as SacCotizacionConcepto[] | []).filter((f) => f.idGrupoConcepto === this.dataGrupos?.idGrupoConcepto);
		}
	}

	convertToDecimal(value: string | number | null | undefined, decimals = 3): string {
		if (value) {
			let numericValue: number;

			if (typeof value === 'number') {
				numericValue = value;
			} else {
				numericValue = parseFloat(value);
			}

			if (isNaN(numericValue)) {
				numericValue = 0;
			}

			return numericValue.toLocaleString(undefined, {
				minimumFractionDigits: decimals,
				maximumFractionDigits: decimals,
			});
		}

		return '0';
	}
}
