import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HistorialEstadoComponent } from './historial-estado.component';
import { InputTextModule, ScAgGridModule } from 'ngx-sumax-erp-component';
import { PortletModule } from '@partials/content/general/portlet/portlet.module';
import { DEFAULT_CONFIG_INPUT_TEXT } from '~shared/interface/ConfigSumaxComponent.intercaes';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
	declarations: [HistorialEstadoComponent],
	imports: [CommonModule, ScAgGridModule, PortletModule, ReactiveFormsModule, FormsModule, InputTextModule.forRoot(DEFAULT_CONFIG_INPUT_TEXT)],
	exports: [HistorialEstadoComponent],
	providers: [],
})
export class HistorialEstadoModule {}
