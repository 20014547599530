export class KeyStorage {
	constructor() {}

	getStorage(key: string) {
		return JSON.parse(String(localStorage.getItem(key)));
	}

	setStorage(key: string, object: any) {
		localStorage.setItem(key, JSON.stringify(object));
	}

	removeStorage(key: string) {
		localStorage.removeItem(key);
	}
}
