/* src/@partials/layout/topbar/notification/notification.component.scss */
:host ::ng-deep ngb-tabset > .nav-tabs {
  display: none;
}
.containerIcon {
  position: relative;
}
.count {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px !important;
  position: absolute;
  top: 20%;
  right: 10%;
  width: 12px;
  height: 12px;
  padding: 8px;
  background-color: red;
  color: #fff;
  border-radius: 50%;
}
.body-form {
  height: 100vh;
}
.icon_cotizacion {
  width: 20px;
  height: 20px;
}
/*# sourceMappingURL=notification.component.css.map */
