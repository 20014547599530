// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@core/security/guards/auth.guard';
import { BaseComponent } from '@theme/base/base.component';
import { ErrorPageComponent } from '@theme/error-page/error-page.component';
import { environment } from 'environments/environment';
import { EnumForm } from '~shared/enums/EnumForm';
import { ControlModule } from './modules/control/control.module';

// Components

const routes: Routes = [
	{
		path: environment.PATH_BASE,
		component: BaseComponent,
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		children: [
			{
				path: 'dashboard',
				data: { titulo: 'Dashboard' },
				loadChildren: () => import('app/modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
			},
			{
				path: 'acuerdo-comercial',
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				data: { titulo: 'Acuerdo comercial', formulario: EnumForm.STA_TarifaLogistico },
				loadChildren: () => import('app/modules/gestion/tarifario-logistico/tarifario-logistico.module').then((m) => m.TarifarioLogisticoModule),
			},
			{
				path: 'catalogo-acuerdo-comercial',
				data: { titulo: 'Catálogo acuerdos comerciales', formulario: EnumForm.STA_TarifaConfiguracion },
				loadChildren: () => import('app/modules/gestion/tarifario-config/tarifario-config.module').then((m) => m.TarifarioConfigModule),
			},
			{
				path: 'cotizacion',
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				data: { titulo: 'Cotizaciones', formulario: EnumForm.STA_Cotizacion },
				loadChildren: () => import('app/modules/gestion/cotizacion/cotizacion.module').then((m) => m.CotizacionModule),
			},
			{
				path: 'reportes',
				data: { titulo: 'Reportes' },
				loadChildren: () => import('app/modules/reporte/reporte.module').then((m) => m.ReporteModule),
			},
			{
				path: 'control',
				data: { titulo: 'Controles' },
				loadChildren: (): Promise<typeof ControlModule> => import('app/modules/control/control.module').then((m) => m.ControlModule),
			},
			// { path: 'error/:type', component: ErrorPageComponent },
			{ path: '', redirectTo: `/${environment.PATH_BASE}/dashboard`, pathMatch: 'full' },
			{ path: '**', redirectTo: '../error/403', pathMatch: 'full' },
		],
	},
	{
		path: 'error/403',
		component: ErrorPageComponent,
		data: {
			type: 'error-v6',
			code: 403,
			title: 'Prohibido pasar',
			desc: 'Tranquilo ratón, aún no desarrollamos esto.<br> Please, contact Almircar.',
		},
	},
	{
		path: 'error/acceso-denegado',
		component: ErrorPageComponent,
		data: {
			type: 'error-v6',
			code: 403,
			title: 'Prohibido pasar',
			desc: 'No tiene permisos para ver esta página',
		},
	},
	{ path: '**', redirectTo: environment.PATH_BASE, pathMatch: 'full' },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			initialNavigation: 'disabled',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
