/* src/@theme/error-page/error-page.component.scss */
kt-error-page {
  height: 100%;
}
.aduanas {
  background-color: #6d88ff;
}
.carga {
  background-color: #ff914d;
}
.finanzas {
  background-color: rgb(180, 26, 26);
}
.transporte {
  background-color: #6c9136;
}
.configuracion {
  background-color: #6c9136;
}
.maestros {
  background-color: rgb(180, 26, 26);
}
.tarifario {
  background-color: #867BC0;
}
.selection > span {
  height: 32.0625px;
  border: 1px solid #e2e5ec;
}
.selection > span:focus {
  background-color: #fff;
  border-color: #9aabff !important;
  outline: 0;
}
.selection > span > .select2-selection__arrow {
  height: 30px !important;
}
.select2-dropdown {
  background-color: white;
  border: 1px solid #e2e5ec !important;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 100%;
  margin-top: 2px !important;
}
.select2-search > .select2-search__field {
  border: 1px solid #e2e5ec !important;
}
.select2-search > .select2-search__field:focus {
  background-color: #fff;
  border-color: #9aabff !important;
  outline: 0;
}
.select2-dropdown {
  margin-top: 10px;
  border-radius: 3.25px !important;
  box-shadow: 0 1px 5px #959595;
}
.select2-container--default.select2-container--open.select2-container--below .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-radius: 4px !important;
}
::ng-deep .dropdown-menu {
  position: absolute;
  top: 10px !important;
  left: 0px;
  will-change: transform;
  transform: translate(353px, 337px);
  box-shadow: 0 1px 5px #959595;
}
.multiselect-dropdown > div > .dropdown-btn {
  height: 32.0625px !important;
  display: inline-block;
  border: 1px solid #e2e5ec !important;
  width: 100%;
  padding: 6px 12px;
  margin-bottom: 0;
  font-weight: 400;
  line-height: 1.52857143;
  text-align: left;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border-radius: 4px;
}
.multiselect-dropdown > div > .dropdown-btn:focus {
  background-color: #fff;
  border-color: #9aabff !important;
  outline: 0;
}
.dropdown-btn > span > .dropdown-down {
  border-color: transparent transparent #888 transparent;
  border-style: solid !important;
  border-width: 5px 4px 0 4px !important;
  height: 0 !important;
  left: 93% !important;
  margin-left: -4px !important;
  margin-top: -2px !important;
  position: absolute !important;
  top: 50% !important;
  width: 0 !important;
}
.dropdown-btn > span > .dropdown-up {
  border-color: transparent transparent #888 transparent;
  border-style: solid !important;
  border-width: 0px 4px 5px 4px !important;
  height: 0 !important;
  left: 93% !important;
  margin-left: -4px !important;
  margin-top: -2px !important;
  position: absolute !important;
  top: 50% !important;
  width: 0 !important;
}
.ag-theme-balham {
  font-family: "Soho Gothic Pro";
  color: #646c9a;
}
no-op.ag-pivot-off {
  font-family: "Soho Gothic Pro";
}
no-op.ag-header-viewport,
no-op.ag-pinned-right-header,
no-op.ag-paging-panel {
  color: #646c9a !important;
}
.mat-expansion-panel-header-title {
  font-family: "Soho Gothic Pro";
}
.mat-expansion-panel-body {
  font-family: "Soho Gothic Pro";
}
button {
  font-family: "Soho Gothic Pro";
}
fieldset.fieldset-border {
  border: 1px solid #ddd !important;
  padding: 0 1.4em 1.4em 1.4em !important;
  margin: 0 0 0 0 !important;
}
legend.fieldset-border {
  font-size: 1.2em !important;
  font-weight: bold !important;
  text-align: left !important;
  width: auto !important;
  padding: 0 10px !important;
  border-bottom: none !important;
}
.mat-expansion-panel {
  color: #646c9a;
}
::ng-deep .ag-body-viewport-wrapper.ag-layout-normal {
  overflow-x: scroll !important;
  overflow-y: scroll !important;
}
::ng-deep ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
}
::ng-deep ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.mat-expansion-panel-header-title {
  color: #646c9a !important;
}
.kt-error-v6 {
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.kt-error-v6 .kt-error_container {
  text-align: center;
}
.kt-error-v6 .kt-error_container .kt-error_subtitle > h1 {
  font-size: 10rem;
  margin-top: 12rem;
  font-weight: 700;
}
.kt-error-v6 .kt-error_container .kt-error_description {
  margin-top: 3rem;
  font-size: 2.3rem;
  font-weight: 500;
  line-height: 3rem;
}
@media (max-width: 768px) {
  .kt-error-v6 .kt-error_container .kt-error_subtitle > h1 {
    font-size: 5rem;
  }
  .kt-error-v6 .kt-error_container .kt-error_description {
    font-size: 1.7rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
/*# sourceMappingURL=error-page.component.css.map */
