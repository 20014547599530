/* src/@partials/layout/splash-screen/splash-screen.component.scss */
:host .kt-splash-screen {
  background-color: #f2f3f8;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  z-index: 1000;
}
:host .kt-splash-screen img {
  margin-bottom: 30px;
}
:host .kt-splash-screen span {
  margin-left: calc(100vw - 100%);
  margin-bottom: 30px;
}
:host .kt-splash-screen ::ng-deep [role=progressbar] {
  margin-left: calc(100vw - 100%);
}
:host .kt-splash-screen ::ng-deep .mat-mdc-progress-spinner circle,
:host .kt-splash-screen ::ng-deep .mat-mdc-progress-spinner circle {
  stroke: #5d78ff;
}
/*# sourceMappingURL=splash-screen.component.css.map */
